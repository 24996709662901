@font-face {
  font-family: 'univers-condensed';
  src:  url('./media/univers-condensed-medium.woff2') format('woff2');
}

@font-face {
  font-family: 'univers-condensed-bold';
  src:  url('./media/univers-condensed-bold.woff2') format('woff2');
  font-weight: bold;
}

@font-face {
  font-family: 'univers';
  src:  url('./media/univers.woff2') format('woff2');
}

@font-face {
  font-family: 'univers-light';
  src:  url('./media/univers-light.woff2') format('woff2');
}

@font-face {
  font-family: 'univers-bold';
  src:  url('./media/univers-bold.woff2') format('woff2');
}

:root {
  --font: univers, sans-serif;
  --font-heading: univers-condensed, univers-condensed;
  --font-heading-bold: univers-condensed-bold, univers-condensed;
}

body {
  background-color: theme('colors.background');
  accent-color: theme('colors.black');
  font-family: univers, sans-serif;
}

h1, h2 {
  font-family: var(--font-heading-bold);
}
h3, h4, h5, a, button {
  font-family: var(--font-heading);
}

.recharts-layer {
  font-family: var(--font);
  font-size: 0.875rem;
  letter-spacing: 0.1px;
  color: theme('colors.micro-genset-curtailed');
}

.recharts-legend-item-text {
  font-size: 0.875rem;
  font-family: var(--font);
  color: theme('colors.black') !important;
}

.App {
  min-height: 100vh;
}

table td input {
  width: 80px;
}

table tr:last-child td input {
  margin-bottom: 10px;
}

table tr td:last-child input {
  margin-right: 5px;
}

.globe-icon,
.currency-icon,
.check-icon {
  width: 36px;
}

svg.low {
  stroke: #CC3A3D;
}

svg.average {
  stroke: #FF7E01;
}

svg.good {
  stroke: theme('colors.yellow');
}

svg.very {
  stroke: #75AD6F;
}

svg.excellent {
  stroke: #1D7044;
}

header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 99;
}

header nav::before {
  content: "";
  background-color: rgba(253,186,18,.6);
  position: absolute;
  left: 0;
  right: 0;
  bottom: -8px;
  height: 8px;
  z-index: 3;
}


header nav::after {
  background-color: rgba(253,186,18,.2);
  bottom: -16px;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 8px;
  z-index: 3;
}

.spacer {
  height: 64px;
}

footer {
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 99;
}

footer svg {
  stroke: theme('colors.black');
  width: 32px;
}

footer img:first-of-type {
  height: 3rem;
}

footer a {
  border-bottom: 1px solid theme('colors.black');
  text-underline-offset: 10%;
}

@media (min-width: 1024px) {
  footer ul li:before {
    content: "|";
    margin: 0 0.25em;
  }

  footer ul li:first-child:before {
    content: "";
    margin: 0;
  }
}

.recharts-legend-wrapper {
  bottom: 0 !important;
}

.recharts-legend-item {
  margin-right: 1.25rem !important;
}

.battery__container--body {
  animation-fill-mode: forwards;
}

.battery__container--load {
  animation: loading 3s ease-in infinite forwards;
}

.battery__container--load::before,
.battery__container--load::after {
  border-radius: 50%;
}

.battery__container--load::before {
  animation: bounce-1 2s linear infinite;
}

.battery__container--load::after {
  animation: bounce-1 2s -0.1s linear infinite;
}

@keyframes loading {
	0%,
	100% {
		height: 1rem;
	}

	70% {
		height: 5rem;
	}

	90%,
	95% {
		height: 8rem;
	}
}

@keyframes bounce-1 {
	14%,
	16%,
	65% {
		height: 20px;
		transform: translateY(-8px);
	}

	40%,
	60% {
		height: 0px;
		transform: translateY(0px);
	}

	50% {
		height: 25px;
		transform: translateY(-13px);
	}
}

.z-100 {
  z-index: 100;
}

